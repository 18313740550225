import {Component, OnDestroy, OnInit} from '@angular/core';
import {MessageService} from '../../messages/service/message.service';
import {Router} from '@angular/router';
import {LineOfBusiness} from './model/line-of-business';
import {SelectCriteriaService} from './service/select-criteria.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AlertMessage} from '../../messages/model/alert-message';
import {HttpErrorResponse} from '@angular/common/http';
import { Dashboards } from '../dashboard/model/dashboards';

@Component({
  selector: 'app-filter',
  templateUrl: './select-criteria.component.html',
  styleUrls: ['./select-criteria.component.css']
})
export class SelectCriteriaComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  title: string;
  loading = false;
  enteredCocode: number;
  selectedYear: number;
  selectedLob: string;
  lobs: LineOfBusiness[] = [];
  oldCocode: number;
  oldYear: number;
  correspondenceId: string;

  constructor(
    private messageService: MessageService,
    private selectCriteriaService: SelectCriteriaService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.title = `Select Letter Criteria`;
    this.selectedYear = 2023;
  }

  ngOnDestroy(): void {
    this.messageService.clear();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  next() {
    let letterBody = {cocode: this.enteredCocode, year: this.selectedYear, lobCode: this.selectedLob };
    this.selectCriteriaService.postStartNewLetter(letterBody)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(
      (res) => {
        this.correspondenceId = res.id;
        this.router.navigate(['/rulesResults', {
          correspondenceId: this.correspondenceId,
          company: this.enteredCocode,
          year: this.selectedYear,
          lob: this.selectedLob,
          lobDesc: this.lobs.find(lob => lob.lobCode === this.selectedLob).lobName
        }], {skipLocationChange: true});

      }
    );

  }

  cancel() {
    this.router.navigate(['/filters']);
  }

  selectYear() {
    if (this.hasValidCocodeYearBeenEntered() && this.oldYear !== this.selectedYear) {
      this.getCompanyActiveLOBs();
    } else {
      this.oldYear = this.selectedYear;
    }

  }

  checkEnteredCocode() {
    if (this.hasValidCocodeYearBeenEntered() && this.oldCocode !== this.enteredCocode) {
      this.getCompanyActiveLOBs();
    }
  }

  hasValidCocodeYearBeenEntered() {
    return (!isNaN(this.enteredCocode) && this.enteredCocode > 0 && !isNaN(this.selectedYear) && this.selectedYear > 0);
  }

  getCompanyActiveLOBs() {
    this.oldCocode = this.enteredCocode;
    this.oldYear = this.selectedYear;
    this.loading = true;

    this.selectCriteriaService.getFiledLOBs(this.selectedYear, this.enteredCocode)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        items => {
          this.lobs = items;
          this.loading = false;
          //TODO: remove this code when backend returns 404 'not found' status
          if (this.lobs.length < 1) {
            this.messageService.clear();
            this.messageService.add(new AlertMessage('No submitted filings available', 'info'));
          }
        },
        (error: HttpErrorResponse) => {
          this.loading = false;
          this.messageService.clear();
          if (error.status === 404) {
            this.messageService.add(new AlertMessage('No submitted filings available', 'info'));
          } else {
            this.messageService.add(new AlertMessage(`Error received - ${error.status}`, 'danger'));
          }

        }
      );
  }

}
