<ngx-loading [show]="loading"></ngx-loading>
<div class="row">
  <main *ngIf="!loading" role="main" class="col-sm-12 ml-sm-auto d-print-none">
    <app-messages [parentName]="'correspondence'"></app-messages>
    <div class="pt-3 pb-3 main-content d-print-block">

      <div class="row">
        <div class="col-12">
          <h3>
            {{title}}
            <hr>
          </h3>
        </div>
      </div>

      <div class="row col-12 pl-2">
          Please choose a company, year and validation criteria.
      </div>
      <br/>

      <form name="selectCriteriaForm" (ngSubmit)="getCompanyActiveLOBs()" #selectCriteriaForm="ngForm">
      <div class="row">
        <div class="col-12">
          <label for="inputCocode" class="col-2 font-weight-bold text-right">Company:</label>
          <input appOnlyDigits id="inputCocode" name="enteredCocode" type="text" pattern="[0-9]*" placeholder="Enter Cocode" size="11" required [(ngModel)]="enteredCocode" (blur)="checkEnteredCocode()" />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <label for="optionYear" class="col-2 font-weight-bold text-right">Year:</label>
          <select id="optionYear" name="selectedYear" class="w-auto" (change)="selectYear()" required [(ngModel)]="selectedYear">
            <option value=""></option>
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023" selected>2023</option>
          </select>
        </div>
      </div>
      </form>

      <div class="row">
        <div class="col-12">
          <label for="optionLob" class="col-2 font-weight-bold text-right">Line of Business:</label>
          <select id="optionLob" name="selectedLob" class="col-2" [disabled]='!selectCriteriaForm.form.valid' required [(ngModel)]="selectedLob">
            <option value="" selected></option>
            <option *ngFor="let lob of lobs" [ngValue]="lob.lobCode">{{lob.lobName}}</option>
          </select>
        </div>
      </div>

      <br/><br/>
      <div class="row">
          <div class="col-4">
            <a id="linkClose" class="float-right mt-2" routerLink="/correspondence">Close</a>
          </div>
          <div class="col-2">
            <button id="buttonNext" type="button" class="btn btn-primary col-8" (click)="next()" [disabled]="!hasValidCocodeYearBeenEntered() || !selectedLob" >Next</button>
          </div>
      </div>

    </div>
    <div class="row">
      <footer class="col-12 footer d-print-none">
        <ncui-footer></ncui-footer>
      </footer>
    </div>
  </main>
</div>
